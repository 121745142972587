import type { RumInitConfiguration } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';

export type DatadogClientProps = RumInitConfiguration;

export const DatadogClient: React.FC<DatadogClientProps> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    if (!isLoaded) return;

    datadogRum.init(props);
    datadogRum.clearUser();
  }, [isLoaded, props]);

  return null;
};
